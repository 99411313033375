import React, { Component } from "react"
import Modal from "react-modal"

export default class extends Component {
  constructor(props) {
    super(props)
    this.dispatch = this.props.dispatch
    Modal.setAppElement("#scf-root")
  }

  render() {
    console.log(this.props.open)
    if (this.props.greenOverlay) {
      return (
        <Modal
          isOpen={this.props.open}
          contentLabel="Modal"
          className={{
            base: "scf-modal",
            afterOpen: "scf-modal_after-open",
            beforeClose: "scf-modal_before-close",
          }}
          overlayClassName={{
            base: "green-overlay",
            afterOpen: "green-overlay_after-open",
            beforeClose: "green-overlay_before-close",
          }}
          onRequestClose={this.props.hidePopup}
          closeTimeoutMS={400}
        >
          {this.props.children}
        </Modal>
      )
    } else {
      return (
        <Modal
          isOpen={this.props.open}
          contentLabel="Modal"
          className={{
            base: "scf-modal",
            afterOpen: "scf-modal_after-open",
            beforeClose: "scf-modal_before-close",
          }}
          overlayClassName={{
            base: "overlay",
            afterOpen: "overlay_after-open",
            beforeClose: "overlay_before-close",
          }}
          onRequestClose={this.props.hidePopup}
          closeTimeoutMS={400}
        >
          {this.props.children}
        </Modal>
      )
    }
  }
}
