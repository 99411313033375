import { 
  RESIZE,
  OPEN_SUMMARY_POPUP,
  CLOSE_SUMMARY_POPUP
} from "./types";


export function resize(size) {
  return {
    type: RESIZE,
    payload: size
  };
}

export function openSummaryPopup() {
  return (dispatch) => {
    dispatch({ type: OPEN_SUMMARY_POPUP, payload: '' });
  }
}

export function closeSummaryPopup() {
  return (dispatch) => {
    dispatch({ type: CLOSE_SUMMARY_POPUP, payload: '' });
  }
}