import React from 'react';
import _ from 'lodash';
import { connect } from "react-redux";
import BaseComponent from './BaseComponent';
//import { NavLink } from 'react-router-dom';
import Calculation from "../components/Calculation";
import MonthlyBox from './MonthlyBox';
import Button from './Button';
import { resize } from '../actions/config';
import Header from "../components/Header"
import { setFinancingType } from '../actions/financing';

class Widget extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      leasingSwitchIndex: this.props.financing.products.findIndex((o)=>{ return o.ident === this.props.financing.selected}),
      positionRight: 0, 
      positionBottom: 0
    }
    this._bind('onScreenChange', 'mouseUp', 'mouseDown', 'drag', 'checkPosition')
    this.startPos = { x: null, y: null }
  }

  componentDidMount() {
    let div = document.getElementById('widget')
    //$(window).on('scroll', this.onScreenChange)
    let position = sessionStorage.getItem('widgetPosition');
    if (position) {
      position = JSON.parse(position)
      div.style.right = position.right
      div.style.bottom = position.bottom
    }

    window.addEventListener('resize', this.onScreenChange);

    if ( this.props.screen.width > 480) {
      this.onScreenChange()
      document.getElementById('widget-header').addEventListener('mousedown', this.mouseDown, false);
      window.addEventListener('mouseup', this.mouseUp, false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onScreenChange);
  }

  mouseUp()
  {
    window.removeEventListener('mousemove', this.drag, true);
    this.startPos = { x: null, y: null }
  }

  mouseDown(e){
    console.log('sdfsdfsd')
    window.addEventListener('mousemove', this.drag, true);
  }

  drag(e) {
    let div = document.getElementById('widget'),
      divRect = div.getBoundingClientRect(),
      bodyRect = document.body.getBoundingClientRect(),
      bottom = window.innerHeight - divRect.bottom,
      right = bodyRect.right - divRect.right,
      top = bodyRect.top - divRect.top,
      left = bodyRect.left - divRect.left

      if (this.startPos.x === null ) {
        this.startPos.x = div.offsetWidth - (e.clientX - divRect.left)
        this.startPos.y = div.offsetHeight - (e.clientY - divRect.top)
      }

      const posRight = -(e.clientX-bodyRect.right) - this.startPos.x;
      const posBottom = -(e.clientY -window.innerHeight) - this.startPos.y

    if (window.innerWidth - posRight >= div.offsetWidth && posRight + window.innerWidth >= window.innerWidth) {
      div.style.right =  posRight + 'px'
      this.setState({positionRight: posRight})
    }

    if (window.innerHeight - posBottom >= div.offsetHeight && posBottom + window.innerHeight >= window.innerHeight) {
      div.style.bottom = -(e.clientY -window.innerHeight) - this.startPos.y + 'px'
      this.setState({positionBottom: posBottom})
    }

    sessionStorage.setItem('widgetPosition', JSON.stringify({right: posRight + 'px', bottom: posBottom + 'px' }));
  }

  checkPosition() {
    const div = document.getElementById('widget');

    if (div) {
      const divRect = div.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      const top = divRect.top - bodyRect.top;
      const left = divRect.left - bodyRect.left;

      if (top < 0) {
        div.style.bottom = (this.props.screen.height - div.offsetHeight) + "px"
      }

      if (left < 0) {
        div.style.right = (document.body.offsetWidth - div.offsetWidth) + "px"
      }
    }
  }

  componentDidUpdate() {
    if ( this.props.screen.width > 480) {
      setTimeout(this.checkPosition, 100)
    }
  }

  onScreenChange(e) {
    const div = document.getElementById('widget')
    // const wHeight = $(window).height()
    // const scroll = $(window).scrollTop()
    // this.setState({ position: Math.max( 20, -((700 - scroll) - wHeight)) })
    if ( this.props.screen.width > 480) {
      setTimeout(this.checkPosition, 100)
    } else {
      div.style.right = 0
      div.style.bottom = 0
    }
  }

  setFinancingType(key) {
    this.setState({ leasingSwitchIndex: key })
    this.props.setFinancingType(key)
  }

  getProduct() {
    return _.find(this.props.financing.products, (o)=>{ return o.ident === this.props.financing.current.ident });
  }

  render() {
    const isLeasing = !this.props.financing.current || this.props.financing.current.rrso === '' ? true : false;
    let content = null;

    const { financing } = this.props;
    const { leasingSwitchIndex } = this.state;
    const product = this.getProduct();

    switch(this.props.config.size) {
      case 'micro':
        
        break;
      case 'mini':
        content = (
          <>
            <div className="radios">
              { financing.products.map((item, key) => {
                return (
                  <div key={ 'switch'+key } className={ leasingSwitchIndex === key ? "radio active" : "radio" } onClick={ ()=>{this.setFinancingType(key)} }><img src="/assets/kia-logo-black.png" alt="KIA Logo"/><span>{ item.name }</span></div>
                )
              })}
            </div>
            <MonthlyBox isLeasing={ isLeasing }/>
            <Button action={ ()=>{this.props.resize("maxi")} }>Więcej opcji</Button>
          </>
        )
        break;
      case 'maxi':
        content = <Calculation />
        break;
    }
    return (
      <div id="widget" className={ "widget "+this.props.config.size }>
        <Header />
        <div className="widget-content"> 
          { content }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { screen, financing, config } = state
  return { screen, financing, config }
};

export default connect(
  mapStateToProps,
  {
    resize,
    setFinancingType
  }
)(Widget);
