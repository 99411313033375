export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";

export const SCREEN_DIMENSIONS_SAVE = "SCREEN_DIMENSIONS_SAVE";

export const FINANCING_INIT_REQUEST = "FINANCING_INIT_REQUEST";
export const FINANCING_INIT_SUCCESS = "FINANCING_INIT_SUCCESS";
export const FINANCING_INIT_FAILURE = "FINANCING_INIT_FAILURE";

export const FINANCING_UPDATE_REQUEST = "FINANCING_UPDATE_REQUEST";
export const FINANCING_UPDATE_SUCCESS = "FINANCING_UPDATE_SUCCESS";
export const FINANCING_UPDATE_FAILURE = "FINANCING_UPDATE_FAILURE";

export const SET_CAR_CONFIG = "SET_CAR_CONFIG";
export const SET_FINANCING_TYPE = "SET_FINANCING_TYPE";
export const SET_INITIAL_PAYMENT = "SET_INITIAL_PAYMENT";
export const SET_PAYMENTS_TIME = "SET_PAYMENTS_TIME";
export const SET_MILAGE = "SET_MILAGE";

export const SEND_CONFIGURATION_REQUEST = "SEND_CONFIGURATION_REQUEST";
export const SEND_CONFIGURATION_SUCCESS = "SEND_CONFIGURATION_SUCCESS";
export const SEND_CONFIGURATION_FAILURE = "SEND_CONFIGURATION_FAILURE";

export const TIMER_START = "TIMER_START";
export const TIMER_FINISHED = "TIMER_FINISHED";

export const CLEAR_FINANCING = "CLEAR_FINANCING";

export const OPEN_POPUP = "OPEN_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";

export const OPEN_RRSO_POPUP = "OPEN_RRSO_POPUP";
export const CLOSE_RRSO_POPUP = "CLOSE_RRSO_POPUP";

export const OPEN_SUMMARY_POPUP = "OPEN_SUMMARY_POPUP";
export const CLOSE_SUMMARY_POPUP = "CLOSE_SUMMARY_POPUP";

export const RESIZE = "RESIZE";

// routing
export const NAVIGATE_HOME = "NAVIGATE_HOME";