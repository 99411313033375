import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Button extends Component {

  render() {
    let cl = 'btn';
    if (this.props.type) {
      cl = 'btn '+this.props.type+'-btn';
    }
    if( !this.props.action ) {
      return (
        <a href={this.props.link} className={"btn "+this.props.type+"-btn"} >{ this.props.children }</a>
      )
    } else {
      return (
        <div className={"btn "+this.props.type+"-btn"} onClick={ this.props.action }>{ this.props.children }</div>
      );
    }
  }
}

export default Button;