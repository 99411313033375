/* Component for easier ES6 binding */

import { Component } from 'react';

export default class BaseComponent extends Component {

	// The rest parameter syntax allows to represent an indefinite number of arguments as an array.
	// https://developer.mozilla.org/pl/docs/Web/JavaScript/Reference/Functions/rest_parameters
	_bind(...methods) {
		methods.forEach( (method) => this[method] = this[method].bind(this) );
	}

	setContent(id) {
		this.setState({ index : id })
	}

	setWrapperHeight(h) {
		this.props.setHeight(h)
	}

	leadingZero(day) {
	  	let d = parseInt(day)
	  	if(d<10) {
	  		return '0'+d
	  	} else {
	  		return ''+d
	  	}
	}

	bulletsToArray(string) {
		let list = string.replace(/• /gi, "").replace(/<P>/gi, "").replace(/<\/P>/gi, "").split(/<br>/gi)
		return list
	}

	parseDate(isoDate, monthWord, showTime) {
		const monthNames = ['Stycznia', 'Lutego', 'Marca', 'Kwietnia', 'Maja', 'Czerwca', 'Lipca', 'Sierpnia', 'Września', 'Października', 'Listopada', 'Grudnia']
		const d = new Date(isoDate)
		const date = new Date(isoDate)
		const time = new Date(d.valueOf() + d.getTimezoneOffset() * 60000)
		const day = date.getDate()
	    const month = date.getMonth()+1
	    const year = date.getFullYear()
	    const hour = time.getHours()
	    const minutes = time.getMinutes()
	    let newDate = '';
		if ( monthWord ) {
			newDate = this.leadingZero(day) + " " + monthNames[month] + " " + year;
		}
		else {
			newDate = this.leadingZero(day) + "." + this.leadingZero(month) + "." + year;
		}

		if ( showTime ) {
			newDate += " ("+this.leadingZero(hour)+":"+this.leadingZero(minutes)+")";
		}

	    return newDate
	}

	getWeekDay = function(isoDate) {
	    const weekday = ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"];
	    const date = new Date(isoDate)
	    return weekday[date.getDay()];
	}


	setCookie(sName, sValue, options) {
    var sCookie = encodeURIComponent(sName) + '=' + encodeURIComponent(sValue);
    if (options && options instanceof Date) {
      options = {
        expires: options
      };
    }
    if (options && typeof options === 'object') {
      if (options.expires) {
        sCookie += '; expires=' + options.expires.toGMTString();
      }
      if (options.path) {
        sCookie += '; path=' + options.path.toString();
      }
      if (options.domain) {
        sCookie += '; domain=' + options.domain.toString();
      }
      if (options.secure) {
        sCookie += '; secure';
      }
    }
    document.cookie= sCookie;
  }

  getCookie(sName) {
    var oCrumbles = document.cookie.split(';');
    for(var i=0; i<oCrumbles.length;i++){
      var oPair= oCrumbles[i].split('=');
      var sKey = decodeURIComponent(oPair[0].trim());
      var sValue = oPair.length>1 ? oPair[1] : '';
      if(sKey === sName) {
        return decodeURIComponent(sValue);
      }
    }
    return false;
  }

  thousandSep(val) {
		let s = ''
		if (val) {
			s = val.toString()
			s = s.replace(/\s+/g, '');
			if (s.length > 3) {
				if (s.indexOf('.') >= 0) {
					let p1 = s.substring(0, s.length-6)
					let p2 = s.substring(s.length-6, s.length)
					s = p1+' '+p2
				} else {
					let p1 = s.substring(0, s.length-3)
					let p2 = s.substring(s.length-3, s.length)
					s = p1+' '+p2
				}
			}
		} else {
			return val
		}

		s = s.replace('.', ',');

		return s
	}

	removeWhiteSpaceFromNumbers(s) {
    console.log('in')
    const n = s.replace(new RegExp(/(\d)( )(\d)/, 'g'), (match,p1,p2,p3) => {
      return p1+'&nbsp;'+p3;
    })
    const nn = n.replace(new RegExp(/(\d)( )(zł)/, 'g'), (match,p1,p2,p3) => {
      return p1+'&nbsp;'+p3;
    })

    return nn;
  }
}