import { SCREEN_DIMENSIONS_SAVE } from "./types";

export function saveDimensionData(data) {
  const dimensions = {
    width: data.width,
    height: data.height,
    orientation: data.height > data.width ? 'portrait' : 'landscape'
  }
  return {
    type: SCREEN_DIMENSIONS_SAVE,
    payload: dimensions
  };
}