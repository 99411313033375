import {
  FINANCING_INIT_REQUEST,
  FINANCING_INIT_SUCCESS,
  FINANCING_INIT_FAILURE,
  FINANCING_UPDATE_REQUEST,
  FINANCING_UPDATE_SUCCESS,
  FINANCING_UPDATE_FAILURE,
  SEND_CONFIGURATION_REQUEST,
  SEND_CONFIGURATION_SUCCESS,
  SEND_CONFIGURATION_FAILURE,
  SET_CAR_CONFIG,
  SET_FINANCING_TYPE,
  SET_PAYMENTS_TIME,
  SET_INITIAL_PAYMENT,
  SET_MILAGE,
  TIMER_START,
  TIMER_FINISHED,
  CLEAR_FINANCING,
  OPEN_POPUP,
  CLOSE_POPUP,
  OPEN_RRSO_POPUP,
  CLOSE_RRSO_POPUP
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SET_CAR_CONFIG:
      return {
        ...state,
        carConfig: action.payload,
      }
    case FINANCING_INIT_REQUEST:
      return {
        ...state
      }
    case FINANCING_INIT_SUCCESS:
      return {
        ...state,
        current: action.payload.financing, 
        products: action.payload.products,
      }
    case FINANCING_UPDATE_REQUEST:
      return {
        ...state,
      }
    case FINANCING_UPDATE_SUCCESS:
      return {
        ...state,
        current: action.payload.financing, 
        products: action.payload.products,
      }
    case SET_FINANCING_TYPE: 
      return {
        ...state,
        selected: action.payload
      }
    case SET_INITIAL_PAYMENT: 
      {
        let paramIndex = state.current.params.findIndex((o)=>{ return o.ident === 'initial'})
        let params = state.current.params
        params[paramIndex].value = action.payload
        return {
          ...state,
          current: {...state.current, params: params}
        }
      }
      break;
    case SET_PAYMENTS_TIME:
      { 
        let paramIndex = state.current.params.findIndex((o)=>{ return o.ident === 'count'})
        let params = state.current.params
        params[paramIndex].value = action.payload
        return {
          ...state,
          current: {...state.current, params: params}
        }
      }
      break;
    case SET_MILAGE: 
      {
        let paramIndex = state.current.params.findIndex((o)=>{ return o.ident === 'additionalRange'})
        let params = state.current.params
        params[paramIndex].value = action.payload
        return {
          ...state,
          current: {...state.current, params: params}
        }
      }
      break;
    case CLEAR_FINANCING:
      return {
        ...state,
        car_price: 75000,
        current: {},
        timerPopup: false
      }
    default:
      return state;
  }
}