import { combineReducers } from 'redux'
import financingReducer from "../reducers/financing";
import toastsReducer from "../reducers/toasts";
import screenReducer from "../reducers/screen";
import configReducer from "../reducers/config";

export default (history) => combineReducers({
  financing: financingReducer,
  toasts: toastsReducer,
  screen: screenReducer,
  config: configReducer
})