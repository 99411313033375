import React from 'react';
import { connect } from "react-redux";
import BaseComponent from './BaseComponent';
import { clearFinancing, startTimer  } from '../actions/financing';
import Button from './Button';

let timerInterval = 0;

class TimerPopup extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { timeLeft: 15 }
  }

  componentWillMount() {
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    window.clearInterval(timerInterval);
  }

  startTimer() {
    timerInterval = window.setInterval(()=>{
      if (this.state.timeLeft === 0) {
        this.props.history.push('/');
        this.props.clearFinancing();
        window.clearInterval(timerInterval);
      } else {
        this.setState({ timeLeft: this.state.timeLeft-1})
      }
    }, 1000)
  }

  render() {
    return (
      <div className="timer-popup">
        <h2>Resetowanie kalkulacji ze względu na brak aktywności</h2>
        <div className="timer">{ this.state.timeLeft+'s'}</div>
        <Button type="white" action={ ()=>{ this.props.startTimer() } }>Wróć</Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, financing } = state
  return { screen, financing }
};

export default connect(
  mapStateToProps,
  {
    clearFinancing,
    startTimer
  }
)(TimerPopup);