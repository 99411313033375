import {
  ADD_TOAST,
  REMOVE_TOAST
} from "../actions/types";

export default function(state = [], action) {

  switch (action.type) {
    case ADD_TOAST:
      return [
        ...state,
        action.payload
      ];
    case REMOVE_TOAST:
      return state.filter(toasts => toasts.id !== action.payload);
    default:
      return state;
  }
}