import {
  FINANCING_INIT_REQUEST,
  FINANCING_INIT_SUCCESS,
  FINANCING_INIT_FAILURE,
  FINANCING_UPDATE_REQUEST,
  FINANCING_UPDATE_SUCCESS,
  FINANCING_UPDATE_FAILURE,
  SEND_CONFIGURATION_REQUEST,
  SEND_CONFIGURATION_SUCCESS,
  SEND_CONFIGURATION_FAILURE,
  SET_CAR_CONFIG,
  SET_FINANCING_TYPE,
  SET_PAYMENTS_TIME,
  SET_INITIAL_PAYMENT,
  SET_MILAGE,
  TIMER_START,
  TIMER_FINISHED,
  CLEAR_FINANCING,
  OPEN_POPUP,
  CLOSE_POPUP,
  OPEN_RRSO_POPUP,
  CLOSE_RRSO_POPUP,
  OPEN_SUMMARY_POPUP,
  CLOSE_SUMMARY_POPUP,
  RESIZE
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case FINANCING_INIT_REQUEST:
      return {
        ...state,
        initPreloader: true
      }
    case FINANCING_INIT_SUCCESS:
      return {
        ...state,
        initPreloader: false
      }
    case FINANCING_UPDATE_REQUEST:
      return {
        ...state,
        preloader: true
      }
    case FINANCING_UPDATE_SUCCESS:
      return {
        ...state,
        preloader: false
      }
    case CLEAR_FINANCING:
      return {
        ...state,
        preloader: false,
        initPreloader: true,
      }
    case OPEN_POPUP:
      return {
        ...state,
        popup: true
      }
    case CLOSE_POPUP:
      return {
        ...state,
        popup: false,
        sendComplete: ''
      }
    case OPEN_RRSO_POPUP:
      return {
        ...state,
        popupRRSO: true
      }
    case CLOSE_RRSO_POPUP:
      return {
        ...state,
        popupRRSO: false
      }
    case OPEN_SUMMARY_POPUP:
      return {
        ...state,
        popupSummary: true
      }
    case CLOSE_SUMMARY_POPUP:
      return {
        ...state,
        popupSummary: false
      }
    case SEND_CONFIGURATION_REQUEST:
      return {
        ...state,
        sendPreloader: true,
        sendComplete: ''
      }
    case SEND_CONFIGURATION_SUCCESS:
      return {
        ...state,
        sendPreloader: false,
        sendComplete: 'success'
      }
    case SEND_CONFIGURATION_FAILURE:
      return {
        ...state,
        sendPreloader: false,
        sendComplete: 'failure'
      }
    case RESIZE:
      return {
        ...state,
        size: action.payload
      }
    default:
      return state;
  }
}